@import './partials/normalize.scss';
@import './partials//colors.scss';

// Utilities
.container {
  padding: 10px;
  background-color: $background-color-white;
}

[disabled] {
  background-color: #999;
}

.control-element {
  width: 100%;
}

.button-light {
  background: transparent;
  color: $font-dark;
}

.warning {
  color: $font-warning
}

.align-right {
  text-align: right;
}

// UI elements
button {
  min-height: 40px;
  border: none;
  background-color: $button-dark;
  color: $font-light;
  padding: 0 10px;
}

select,
input,
textarea {
  background: transparent;
  min-height: 30px;
  border: 1px #333 solid;
}
 
// Typography
body {
  font: 14px sans-serif;
  color: $font-dark;
  background-color: $background-light;
}

p,
a {
  font-size: 1em;
}

a {
  color: $font-dark;
  text-decoration: none;
}

// Navigation
.nav {
  padding: 20px;
  background-color: $background-color-white;
  display: flex;
  .brand {
    font-size: 1em;
    width: calc( 100% - 54px);
  }
  .menu {
    color: $font-dark;
    background: transparent;
    min-height: 0;
    margin: 10px;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: -20px;
  }

}

// Hero
.main {
  margin: 20px;
  .hero-text {
    font-size: 3em;
  }
  .sidenote {
    margin-bottom: 3em;
  }
  .page-header {
    margin: 0px;
  }
  .task {
    margin-top: 20px;
    .task-desc {
      margin-top: 0;
    }
    .taskFooter {
      display: flex;
      .task-time {
        margin-top: 10px;
        text-align: right;
        width: calc(100% - 60px);
      }
    }
  }
}

.task-container {
  margin-bottom: 80px;
}

.center-float {
  text-align: center;
  position: fixed;
  width: calc(100% - 20px);
  bottom: 0px;
  padding: 10px;
  background: $background-color-white;
  button {
    width: 80%;
  }
  .new-task {
    text-align: left;
    padding: 20px;
    padding-top: 0;
    padding-bottom: 0;
    .new-task-header {
      display: flex;
      .form-header {
        margin-bottom: 20px;
        width: calc(100% - 30px);
      }
      .close-new {
        width: 50px;
        background:transparent;
        color: $font-dark;
        min-height: 0;
        margin-top: -8px;
        padding-right: 0;
        margin-right: -20px;
      }
    }
    
    .submit-button {
      text-align: center;
      margin: 20px 0;
      button {
        width: 100%;
      }
    }
  }
}

.form-footer {
  display: flex;
  .priority {
    width: 100%;
  }
  .estimate {
    select {
      margin-left: 10px;
    }
  }
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $background-dark;
  opacity: 0.8;
}

.task-detail {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 80px);
  margin: 20px;
  padding: 20px;
  background: $background-color-white;
  .task-detail-header {
    display: flex;
    margin-bottom: 20px;
    .task-detail-para {
      width: calc(100% - 56px);
    }
  }
  .buttons {
    text-align: center;
    margin-top: 40px;
    button {
      width: 100%;
      margin-bottom: 5px;
    }
  }
}

// Todo

.todo-container {
  margin-top: 20px;
}

.input-group {
  label {
    input[type="checkbox"] {
      min-height: 0;
      transform: scale(1.2);
      
    }
    span {
      margin-left: 20px;
    }
  }
}

.todo {
  margin-bottom: 20px;
}

.todo-footer {
  margin-top: 20px;
  display: flex;
  span {
    width: 50%;
  }
}

.todo-opt {
  text-align: center;

  button {
    width: 80%;
    margin-bottom: 10px;
  }
}

