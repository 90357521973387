// Color pallete
$font-dark: #333;
$font-lighter: #666;
$font-light: #fff;
$font-warning:#ff8d8d;

$background-light: #f3f3f3;
$background-color-white: #fff;
$background-dark: #333;

$button-dark: #333;

$warning: #ff8d8d;
$warning-light: #ffcdcd;